.Toastify__toast {
  @apply rounded-xl;
}
.Toastify__toast-body {
  @apply break-all overflow-hidden font-sans text-sm font-medium
    text-gray-900 dark:text-gray-100 !important;
}
.Toastify__toast {
  @apply bg-gray-100 dark:bg-gray-700
    border border-gray-200 dark:border-gray-600
    shadow-2xl !important;
}

.Toastify__close-button {
  @apply text-gray-900 dark:text-gray-100 !important;
}
