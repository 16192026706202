.container-0 {
  @apply mx-auto max-w-7xl px-4 sm:px-6 lg:px-8;
}
.main-gradiant {
  @apply bg-gradient-to-r from-pink-600 to-purple-600;
}

.snappy-transition {
  @apply transition-all ease-in-out delay-75 duration-100;
}

.normal-transition {
  @apply transition-all ease-in-out duration-500;
}

/* Feature Card */
.feature-card-text-container {
  @apply px-4 sm:px-[2rem] py-2 sm:py-5 md:py-0 md:max-w-md  md:mx-auto
          md:min-w-[50%] lg:min-w-[45%];
}

.feature-card-text-title {
  @apply text-left 
  text-2xl sm:text-3xl font-bold;
}

.feature-card-text-subtext {
  @apply mt-1 sm:mt-3
  text-base sm:text-lg font-semibold text-gray-500
  text-left;
}

.card-blured-bg {
  @apply bg-gray-200/50 backdrop-blur-md;
}

.main-background {
  @apply min-h-screen bg-gradient-to-br bg-fixed from-red-50 
   to-blue-50
   overflow-hidden justify-center;
}

.button-action {
  @apply inline-flex items-center rounded-3xl font-semibold text-white 
  bg-gradient-to-br from-indigo-500 to-indigo-700
  hover:from-indigo-600 hover:to-indigo-900
  hover:ring-2 hover:ring-inset hover:ring-indigo-500 hover:ring-opacity-30
  focus:ring-2 focus:ring-inset focus:ring-indigo-500 focus:ring-opacity-100
  focus:outline-none;
}

.form-labels {
  @apply font-medium text-lg text-gray-900;
}
